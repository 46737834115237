var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ActionBaseButton", {
    attrs: {
      options: _vm.options,
      dismissible: _vm.dismissible,
      disabled: _vm.disabled,
      "fixed-content": _vm.fixedContent
    },
    on: { focus: _vm.onFocus, hover: _vm.onHover, input: _vm.onSelect },
    scopedSlots: _vm._u(
      [
        {
          key: "button",
          fn: function() {
            return [
              _c(
                "Button",
                {
                  attrs: {
                    theme: _vm.theme,
                    size: _vm.size,
                    title: _vm.title,
                    disabled: _vm.disabled
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prepend",
                        fn: function(ref) {
                          var color = ref.color
                          return [
                            _vm._t("prepend", null, {
                              focused: _vm.isFocused,
                              color: color
                            })
                          ]
                        }
                      },
                      {
                        key: "append",
                        fn: function(ref) {
                          var color = ref.color
                          return [
                            _vm._t(
                              "append",
                              function() {
                                return [
                                  _c("IconArrowDropdown", {
                                    attrs: {
                                      "fill-color": color,
                                      state: _vm.iconArrowState
                                    }
                                  })
                                ]
                              },
                              { focused: _vm.isFocused, color: color }
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [_vm._v(" "), _vm._v(" "), _vm._t("default")],
                2
              )
            ]
          },
          proxy: true
        },
        {
          key: "option",
          fn: function(ref) {
            var option = ref.option
            return [_vm._t("option", null, { option: option })]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
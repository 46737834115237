var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(_vm.feedbackIconComponent, {
        tag: "Component",
        attrs: { "fill-color": _vm.feedbackIconFillColor }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "alert__title",
        domProps: { textContent: _vm._s(_vm.message) }
      }),
      _vm._v(" "),
      _c("Fab", {
        attrs: { theme: _vm.secondaryPlainTheme },
        nativeOn: {
          click: function($event) {
            return _vm.onRemoveAlert.apply(null, arguments)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function(ref) {
              var color = ref.color
              return [_c("IconClose", { attrs: { "fill-color": color } })]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ActionBaseButton", {
    style: _vm.floatingStyle,
    attrs: {
      options: _vm.options,
      dismissible: _vm.dismissible,
      disabled: _vm.disabled,
      "fixed-content": _vm.fixedContent
    },
    on: { focus: _vm.onFocus, hover: _vm.onHover, input: _vm.onSelect },
    scopedSlots: _vm._u(
      [
        {
          key: "button",
          fn: function() {
            return [
              _c("Fab", {
                attrs: {
                  theme: _vm.theme,
                  size: _vm.size,
                  disabled: _vm.disabled
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "icon",
                      fn: function() {
                        return [
                          _vm._t(
                            "icon",
                            function() {
                              return [
                                _c("IconArrowDropdown", {
                                  attrs: {
                                    "fill-color": _vm.iconFillColor,
                                    state: _vm.iconArrowState
                                  }
                                })
                              ]
                            },
                            { color: _vm.iconFillColor }
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            ]
          },
          proxy: true
        },
        {
          key: "option",
          fn: function(ref) {
            var option = ref.option
            return [_vm._t("option", null, { option: option })]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "advanced-filters-placeholder" },
    [
      _c("IconDragDrop", { attrs: { "fill-color": _vm.graphiteLightColor } }),
      _vm._v("\n    Drag the attribute to add a filter\n")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm._l(_vm.orderedFilters, function(filter, index) {
        return _c(_vm.filterComponents[filter.type], {
          key: filter.id,
          tag: "Component",
          attrs: {
            scope: _vm.scope,
            index: index,
            value: _vm.value[filter.id],
            filter: filter,
            draggable: _vm.draggable
          },
          on: { remove: _vm.onRemove, swap: _vm.onSwap, apply: _vm.onApply }
        })
      }),
      _vm._v(" "),
      _c("AdvancedFilterPlaceholder", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isPlaceholderVisible,
            expression: "isPlaceholderVisible"
          }
        ]
      }),
      _vm._v(" "),
      _vm._t(
        "removeAllButton",
        function() {
          return [
            _c("AdvancedFiltersRemoveAllButton", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.orderedFilters.length,
                  expression: "orderedFilters.length"
                }
              ],
              nativeOn: {
                click: function($event) {
                  return _vm.onRemoveAll.apply(null, arguments)
                }
              }
            })
          ]
        },
        { visible: _vm.orderedFilters.length }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "advanced-filters-remove-all-button",
      attrs: { "aria-label": _vm.title, type: "button" }
    },
    [_c("IconCancel"), _vm._v("\n    " + _vm._s(_vm.title) + "\n")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }